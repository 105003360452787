import { pick } from './random-utils';

const names = {
    male: [
        "Aaron",
        "Adin",
        "Ahmed",
        "Andrej",
        "Artyom",
        "Ben",
        "Biel",
        "Charlie",
        "Connor",
        "Daan",
        "Damjan",
        "Daniel",
        "David",
        "Dmitry",
        "Edvin",
        "Enzo",
        "Eric",
        "Filipe",
        "Finn",
        "George",
        "Goran",
        "Harry",
        "Hugo",
        "Ibai",
        "Ivan",
        "Jack",
        "Jonas",
        "João",
        "Jules",
        "Jon",
        "Lazar",
        "Leo",
        "Liam",
        "Logan",
        "Lucas",
        "Luis",
        "Marko",
        "Martin",
        "Mikhail",
        "Milan",
        "Milos",
        "Mat",
        "Nathan",
        "Neil",
        "Nik",
        "Oliver",
        "Oskar",
        "Pablo",
        "Petar",
        "Ralfs",
        "Raphael",
        "Rayan",
        "Roberts",
        "Rodrigo",
        "Roman",
        "Samuel",
        "Sem",
        "Sergei",
        "Sean",
        "Stefan",
        "Szymon",
        "Theo",
        "Tobias",
        "Tomas",
        "Viktor",
        "Vuk",
        "William",
        "Wout",
        "Zach",
        "Zoran"
    ],
    female: [
        "Aada",
        "Alice",
        "Alisa",
        "Alma",
        "Alva",
        "Amelia",
        "Ana",
        "Asja",
        "Aurora",
        "Aya",
        "Beatriz",
        "Chloe",
        "Daisy",
        "Daria",
        "Dunja",
        "Eevi",
        "Elena",
        "Eliza",
        "Ellen",
        "Elsa",
        "Ema",
        "Emilija",
        "Emily",
        "Eva",
        "Gabija",
        "Grace",
        "Hanna",
        "Ida",
        "Ioana",
        "Irati",
        "Irina",
        "Isa",
        "Isla",
        "Ivana",
        "Jade",
        "Jana",
        "Jelena",
        "Jovana",
        "June",
        "Jazmin",
        "Julia",
        "Klea",
        "Lamija",
        "Lara",
        "Lena",
        "Leonor",
        "Liepa",
        "Lili",
        "Lina",
        "Louise",
        "Lucia",
        "Lea",
        "Mae",
        "Maja",
        "Margret",
        "Maria",
        "Melisa",
        "Mia",
        "Milena",
        "Nahia",
        "Nela",
        "Nina",
        "Nora",
        "Olga",
        "Olivia",
        "Paula",
        "Sara",
        "Sofia",
        "Stella",
        "Tatjana",
        "Tereza",
        "Vesna",
        "Vicky",
        "Yasmine",
        "Yelena",
        "Zala",
        "Zoe"
    ]
};

const lastNames = [
    "Alievi",
    "Andov",
    "Bauer",
    "Boyko",
    "Campbell",
    "Crown",
    "Davies",
    "Dubois",
    "Esposito",
    "Fontana",
    "Garcia",
    "Gruber",
    "Hansen",
    "Huber",
    "Ivanov",
    "Jones",
    "Kaya",
    "Kovacs",
    "Larsen",
    "Lindholm",
    "Maric",
    "Magi",
    "Nagy",
    "Novak",
    "O'Connor",
    "Patel",
    "Pop",
    "Rohr",
    "Rusu",
    "Santos",
    "Schmidt",
    "Smith",
    "Tamm",
    "Virtanen",
    "Wagner",
    "Walsh",
    "de Vries"
];

export const generateName = (gender) => {
    let lastName = pick(...lastNames).split("/");
    return `${pick(...names[gender])} ${(gender === "female" && lastName[1]) ? lastName[1] : lastName[0]}`;
};